import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import { useLocation } from "react-router-dom";
import { getPageSection } from "api/pages";
import PackagesArray from "./PackagesArray";
import { toast } from "react-toastify";

const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname.split("/")[2];
  const [data, setData] = useState([]);
  const [packages, setPackages] = useState([]);

  const { handleSubmit, reset } = useForm();

  const onStart = async () => {
    const pageData = await getPageSection("home", slug);
    setData(pageData);
    setPackages(pageData.packages);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      title: data?.title,
      description: data?.description,
      packages: data?.packages,
    });
  }, [reset, data]);

  const onSubmit = async (data) => {
    try {
      data.packages = packages;
      // eslint-disable-next-line no-unused-vars
      const savedResponse = await server().put(`/pages/home/sections/${slug}`, {
        section: data,
      });

      toast.success("تم الحفظ", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } catch (error) {
      toast.error("يوجد خطأ فى إرسال البيانات ", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xxl={8} lg={12} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <PackagesArray
                      packages={packages}
                      setPackages={setPackages}
                    />
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
