import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import { getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

//Home Sections
import HeroIndex from "views/home/heroSection/Index";
import AboutIndex from "views/home/aboutSection/Index";
import PackagesIndex from "views/home/packagesSection/Index";

//contact
import Contact from "views/contactUs/Index";
import Show from "views/contactUs/Show";

//settings
import Settings from "views/settings/Index";
import SocialTypes from "views/socialTypes/Index";
import AddType from "views/socialTypes/AddType";
import EditType from "views/socialTypes/EditType";
import Socials from "views/socials/Index";
import AddSocial from "views/socials/AddSocial";
import EditSocial from "views/socials/EditSocial";

//admins
import Admins from "views/admins/Index";
import AddAdmin from "views/admins/AddAdmin";
import EditAdmin from "views/admins/EditAdmin";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn } = useSelector((state) => state?.authentication);

  useEffect(() => {
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />

              {/*Home Sections */}
              <Route path="/home/hero" element={<HeroIndex />} />
              <Route path="/home/packages" element={<PackagesIndex />} />
              <Route path="/home/about" element={<AboutIndex />} />

              {/* contact */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/contact/show/:id" element={<Show />} />

              {/* settings */}
              <Route path="/settings" element={<Settings />} />
              {/*socialTypes */}
              <Route path="/types" element={<SocialTypes />} />
              <Route path="/types/add" element={<AddType />} />
              <Route path="/types/:id" element={<EditType />} />

              {/*socials */}
              <Route path="/socials" element={<Socials />} />
              <Route path="/socials/add" element={<AddSocial />} />
              <Route path="/socials/:id" element={<EditSocial />} />

              {/*admins */}
              <Route path="/admins" element={<Admins />} />
              <Route path="/admins/add" element={<AddAdmin />} />
              <Route path="/admins/:id" element={<EditAdmin />} />

              <Route path="*" element={<NotFound />} />
            </Route>

            {/*Auth Routes */}
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
