import React from "react";
import removeImg from "../../../assets/svgs/close.svg";
import { v4 } from "uuid";
import { Col, Row } from "react-bootstrap";

const SlidesArray = ({ fields, append, remove, register, errors }) => {
  return (
    <div className="form-group branches features">
      <h5>
        عناصر مقدمة الموقع
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            append({});
          }}
        >
          إضافة عنصر
        </button>
      </h5>
      <Row>
        {fields.map((item, index) => {
          return (
            <Col xl={4} lg={6} md={6} xs={12} key={index}>
              <div key={item.id} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row>
                  <Col lg={12} xs={12}>
                    <input
                      type="hidden"
                      value={v4()}
                      {...register(`slides.${index}.id`)}
                    />
                    <div className="form-group">
                      <h5>عنوان العنصر</h5>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="عنوان العنصر"
                        {...register(`slides.${index}.title`, {
                          required: true,
                        })}
                      />
                      <p className="error-hint">
                        {errors.slides?.["title"]?.type === "required" &&
                          "يرجي ادخال  عنوان العنصر"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>وصف العنصر</h5>
                      <textarea
                        className="form-control form-outline"
                        placeholder="وصف العنصر"
                        {...register(`slides.${index}.description`, {
                          required: true,
                        })}
                      ></textarea>
                      <p className="error-hint">
                        {errors.slides?.["description"]?.type === "required" &&
                          "يرجي ادخال  وصف العنصر"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SlidesArray;
