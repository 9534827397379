import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { editSocial, getSocial } from "api/socials";
import { getAllSocialTypes } from "api/socialTypes";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

const EditSocial = () => {
  const { locale } = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState();
  const [socialTypes, setSocialTypes] = useState([]);

  const { register, handleSubmit, reset } = useForm();

  const onStart = async () => {
    const pageData = await getSocial(id);
    const socialTypesData = await getAllSocialTypes();
    setData(pageData);
    setSocialTypes(socialTypesData);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      name: data?.name,
      link: data?.link,
      socialType: data?.socialType?.id,
    });
  }, [data, reset]);
  const onSubmit = (data) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = editSocial(id, data);
      navigate("/socials");

      toast.success("تم الحفظ", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } catch (error) {
      toast.error("يوجد خطأ فى إرسال البيانات ", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل البيانات</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>نوع وسيلة التواصل</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("socialType", { required: true })}
                        >
                          <option>يرجي اختيار وسيلة التواصل</option>
                          {socialTypes?.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name?.[locale]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الوسيلة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الوسيلة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الوسيلة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الوسيلة بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>رابط الوسيلة</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رابط الوسيلة"
                          {...register("link", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSocial;
