import React from "react";
import removeImg from "../../../assets/svgs/close.svg";
import { v4 } from "uuid";
import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";

const PackagesArray = ({ packages, setPackages }) => {
  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;

    const newPackagesArray = packages.map((myPackage) => {
      if (myPackage.id !== id) return myPackage;
      return { ...myPackage, [name]: value };
    });

    setPackages(newPackagesArray);
  };

  const removePackage = (packageId) => {
    const newPackages = packages.filter(
      (myPackage, index) => myPackage.id !== packageId
    );
    setPackages(() => newPackages);
  };

  const addPackage = () => {
    setPackages(() => [
      ...packages,
      {
        id: v4(),
        path: "",
        title: "",
        description: "",
      },
    ]);
  };

  return (
    <div className="form-group branches features">
      <h5>
        الباقات
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            addPackage();
          }}
        >
          إضافة باقة
        </button>
      </h5>
      <Row>
        {packages?.map((myPackage, index) => {
          return (
            <Col xxl={4} lg={6} md={6} xs={12} key={index}>
              <div key={myPackage.id} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => removePackage(myPackage.id)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row>
                  <Col lg={12}>
                    <ImageUpdate
                      item={myPackage}
                      packages={packages}
                      setPackages={setPackages}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>عنوان الباقة</h5>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="عنوان الباقة"
                        name="title"
                        onChange={(e) => handleInputOnChange(e, myPackage.id)}
                        value={myPackage?.title}
                      />
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>وصف الباقة</h5>
                      <textarea
                        className="form-control form-outline"
                        placeholder="وصف الباقة"
                        name="description"
                        onChange={(e) => handleInputOnChange(e, myPackage.id)}
                        value={myPackage?.description}
                      ></textarea>
                    </div>
                  </Col>
                  <input type="hidden" value={v4()} />
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default PackagesArray;
